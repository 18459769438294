import Menu from './components/Menu'
import Hero from './components/Hero'
import Projects from './components/Projects'
import About from './components/About'
import BooksSection from './components/BooksSection'
import Footer from './components/Footer'

function App() {
    return (
        <>
            <Menu />
            <Hero />
            <Projects />
            <About />
            <BooksSection />
            <Footer />
        </>
    )
}


export default App
