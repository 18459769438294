import styled from 'styled-components/macro'
import TytleBackground from './brush.svg'

import '../../index-old.css'
import '../../projects.css'

const Projects = () => {
    return (
        <>
            <StyledProjectsTitle>
                <h1>Projects</h1>
                <h2>
                    Here is the brief overview of projects I made to learn
                    programming
                </h2>
                <p>
                    I made them to learn something new and have some fun along
                    the way
                </p>
            </StyledProjectsTitle>
            <StyledProjectsContainer>
                <div className="project-astronauts">
                    <div className="background">
                        <img src="images/astronauts-illustration.svg" alt="astronauts-illustration" />
                    </div>
                    <div className="spacer"></div>
                    <div className="text-astronauts">
                        <h3>Astronauts</h3>
                        <p>
                            Let’s see who is on the orbit on International Space
                            Station in real time!
                        </p>
                        <p>Tech: React, hooks, NASA Open API</p>
                        <div className="button-git">
                            <button>Read more</button>
                            <img src="images/projects-git.svg" alt="projects-git"/>
                        </div>
                    </div>
                </div>
                <div className="project-memes">
                    <div className="background">
                        <img src="images/cats.png" alt="cats"/>
                    </div>
                    <div className="spacer"></div>
                    <div className="text-memes">
                        <h3>Meme exchange</h3>
                        <p>
                            Upload your best meme for me and it randomly shows
                            you one from my collection.
                        </p>
                        <p>Tech: Firebase, Vanila JavaScript, HTML, CSS</p>
                        <div className="button-git">
                            <button>Read more</button>
                            <img src="images/projects-git.svg" alt="Projects-git" />
                        </div>
                    </div>
                </div>
                <div className="project-coffin">
                    <div className="background">
                        <img src="images/coffin-illustation.svg" alt="offin-illustation" />
                    </div>
                    <div className="spacer"></div>
                    <div className="text-coffin">
                        <h3>Coffin calc</h3>
                        <p>
                            Calculate your lifetime expectancy based on age,
                            location and gender. Beware of dark humor.
                        </p>
                        <p>Tech: React, hooks, WHO data (CSV to Json)</p>
                        <div className="button-git">
                            <button>Read more</button>

                            <img src="images/projects-git.svg" alt="rojects-git"/>
                        </div>
                    </div>
                </div>
            </StyledProjectsContainer>
            <div className="project-spacify">
                <div className="background">
                    <div className="background-spacify-container">
                        <div className="background-spacify"></div>
                    </div>
                    <img src="images/spacify-cards.svg" alt="spacify-cards"/>
                </div>
                <div className="spacer"></div>
                <div className="text-spacify">
                    <h3>Spacify</h3>
                    <p>
                        I was working for Spacify as a freelance React developer
                        and porting their React Native app to the web platform.
                        Also writing test cases for integration and UI testing.
                    </p>
                    <p>Tech: React, hooks, styled components, GraphQL</p>
                </div>
            </div>
            <StyledProjectsContainer>
                <div className="project-toggle">
                    <div className="background">
                        <img src="images/toggle-illustration.svg" alt="toggle-illustration"/>
                    </div>
                    <div className="spacer"></div>
                    <div className="text-toggle">
                        <h3>Toggle</h3>
                        <p>
                            Here is the little side-project, which looks simple
                            on the outside, but was immensely complex for me.
                        </p>
                        <p>Tech: Figma, Javascript, React</p>
                    </div>
                </div>
                <div className="project-personal">
                    <div className="background">
                        <img src="images/personal-illustration.svg" alt="personal-illustration"/>
                    </div>
                    <div className="spacer"></div>
                    <div className="text-personal">
                        <h3>Personal page (this one)</h3>
                        <p>
                            This is my first real world project I made on my
                            own. It was tears of pain and joy, I learned a lot.
                        </p>
                        <p>Tech: React (hooks, context), Gatsby, Sanity CMS</p>
                    </div>
                </div>
            </StyledProjectsContainer>
        </>
    )
}

const StyledProjectsContainer = styled.section`
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 26px;
    padding-right: 23px;
    padding-left: 23px;

    & > * {
        border-radius: 20px;
        margin-bottom: 36px;
        overflow: hidden;
    }
`

const StyledProjectsTitle = styled.div`
    font-style: normal;
    display: flex; /*Если оставить тут flex, но убрать align-items, то пропадает нормальный градиент */
    flex-direction: column;
    align-items: flex-start;

    background-image: url(${TytleBackground});
    background-repeat: no-repeat;
    min-height: 226px;
    background-size: contain;

    margin: 0;
    padding: 0px;
    padding-right: 23px;
    padding-left: 23px;
    padding-top: 22px;

    & > * {
        padding-bottom: 16px;
    }

    h1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        margin: 0;

        background: linear-gradient(114.12deg, #885bed -5.01%, #ff167c 110.37%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;

        color: #000000;
        margin: 0;
    }
    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 130%;

        color: #000000;
        opacity: 0.6;
        margin: 0;
    }
`

export default Projects
