import styled from 'styled-components/macro'
import Dot from './images/dot.svg'

const StyledStr = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const StyledDots = styled.div`
    width: 100%;
    background-image: url(${Dot});
    background-repeat: repeat-x;
    background-position-y: bottom;
`
const DotsInfo = styled.div`
    width: 100%;
`

const Str = ({ title, info }) => (
    <StyledStr>
        <p>{title}:</p>
        <StyledDots />
        <p>{info}</p>
    </StyledStr>
)



const About = () => {
    return (
        <>
            <StyledAboutTitleSection>
                <h1>About me</h1>
                <h2>
                    “Annie is an astute, decisive, and resourceful character
                    with a delightful sense of taste and humor. I guess you've
                    already got it from her website.”
                </h2>
                <p classNameName="sign">— Denis Semenov, Spacify founder</p>
            </StyledAboutTitleSection>
            <StyledInfo>
                <h1>Info</h1>
                <DotsInfo>
                    <Str title="Name" info="Annie" />
                    <Str title="Age" info={myAge} />
                    <Str title="Locaition" info="Berlin,&nbsp;Germany" />
                </DotsInfo>
                <h1>Education</h1>
                <div>
                    <p>
                        St.Petersburg Polytechnic University Engineering
                        management, economics, Investments management.
                    </p>
                    <div className="degree-date">
                        <p>Master Degree</p>
                        <p>2016 - 2018</p>
                    </div>
                </div>
                <div>
                    <p>
                        Academy of National Economy and
                        Public Administration Global economy, economics, and
                        finances.
                    </p>
                    <div className="degree-date">
                        <p>Bachelor</p>
                        <p>2012 - 2016</p>
                    </div>
                </div>
                <h1>Languages</h1>
                <DotsInfo>
                    <Str title="English" info="Upper-Intermediate" />
                    {/* <Str title="Russian" info="Native" /> */}
                </DotsInfo>
            </StyledInfo>
            <div className="skills">
                <div className="skills-text">
                    <h1>Skills</h1>
                    <p>
                        <strong>Programming languages:</strong>
                        <br />
                        JavaScript
                    </p>
                    <div className="skills-frameworks">
                        <p>
                            <strong>Frameworks and technologies:</strong>
                            <br />
                            React, state management with Context API Gatsby
                        </p>
                        <p>
                            Styling with CSS, Styled Components Adaptive layouts
                            and CSS Grid Custom fonts
                        </p>
                        <p>
                            GraphQL with Contentful and Gatsby, Sanity headless
                            CMS
                        </p>
                        <p>
                            Timeline-based animation using keyframes
                            <br />
                            SVG Animation
                        </p>
                    </div>
                    <div className="skills-frameworks">
                        <p>
                            <strong>Infrastructure and CI/CD:</strong>
                            <br />
                            AWS Amplify, Netlify deployments.
                            <br />
                            Stripe payments integration
                        </p>
                    </div>
                    <p>
                        <strong>Design and prototyping:</strong>
                        <br />
                        Figma, Photoshop, Illustrator, Lightroom
                    </p>
                    <p>
                        <strong>Project management:</strong>
                        <br />
                        Jira, Trello, Asana
                        <br />
                        Master of Spreadsheets :)
                    </p>
                    <p>
                        <strong>Version control and collaboration:</strong>
                        <br />
                        Git and GitHub
                    </p>
                </div>
                <div className="skills-girl"></div>
            </div>
        </>
    )
}

// Фкнкция для вычисления возраста и отоьражения его в компоненте
function getAge(dateOfBirth) {
    const today = new Date()
    const birthDate = new Date(dateOfBirth)
    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()
    if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
        age--
    }
    return age
}

const myAge = getAge('1994-05-16')


const StyledAboutTitleSection = styled.div`
    font-style: normal;
    display: flex; /*Если оставить тут flex, но убрать align-items, то пропадает нормальный градиент */
    flex-direction: column;
    align-items: flex-start;

    background-image: url('images/brush.svg');
    background-repeat: no-repeat;
    min-height: 226px;
    background-size: contain;

    margin: 0;
    padding: 0px;
    padding-right: 23px;
    padding-left: 23px;
    padding-top: 22px;

    & > * {
        padding-bottom: 16px;
    }

    h1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        margin: 0;

        background: linear-gradient(114.12deg, #885bed -5.01%, #ff167c 110.37%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;

        color: #000000;
        margin: 0;
    }

    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 130%;

        color: #000000;
        opacity: 0.6;
        margin: 0;
    }

    .sign {
        width: 100%;
        text-align: right;
    }
`

const StyledInfo = styled.div`
    font-family: Roboto;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0px;
    padding-right: 23px;
    padding-left: 23px;
    padding-top: 0px;
    margin-top: -15px;

    & > * {
        padding-bottom: 16px;
    }

    h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;

        background: linear-gradient(114.12deg, #885bed -5.01%, #ff167c 110.37%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        margin: 0;
    }

    .degree-date {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`

export default About
