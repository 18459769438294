import styled from 'styled-components/macro'
import LowerFigures from './images/lower-figures.svg'

const BooksList = () => {
    return (
        <Background>
            <StyledBooksList>
                <img src="images/books/book1-dalio.jpg" alt ="book1"/>
                <img src="images/books/book2-frikonomics.jpg" alt ="book2"/>
                <img src="images/books/book3-fayman.jpg" alt ="book3"/>
                <img src="images/books/book4-sapience.jpg" alt ="book4"/>
                <img src="images/books/book5-taleb.jpg" alt ="book5"/>
                <img src="images/books/book6-art.jpg" alt ="book6"/>
                <img src="images/books/book7-babylon.jpg" alt ="book7"/>
                <img src="images/books/book8-customers.jpg" alt ="book8"/>
                <img src="images/books/book9-foer.jpg" alt ="book9"/>
                <img src="images/books/book10-foer2.jpg" alt ="book10"/>
                <img src="images/books/book11-eco.jpg" alt ="book11"/>
            </StyledBooksList>
        </Background>
    )
}

const Background = styled.div`
    background-image: url(${LowerFigures});

    background-repeat: no-repeat;
    background-position: right top;
    background-position-y: -50px;
`

const StyledBooksList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 6px;
    padding-top: 24px;
    padding-bottom: 24px;

    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    & > * {
        padding-right: 12px;
    }

    img {
        height: 160px;
    }

    & > * {
        padding-bottom: 16px;
    }
`

export default BooksList
