import styled from 'styled-components'
import { ReactComponent as InstaIcon } from './icons/instagram-icon.svg'
import { ReactComponent as LinkedInIcon } from './icons/linkedin-icon.svg'
import { ReactComponent as TwitterIcon } from './icons/twitter-icon.svg'
import { ReactComponent as GithubIcon } from './icons/github-icon.svg'
import { ReactComponent as TelegramIcon } from './icons/telegram-icon.svg'
import { ReactComponent as CvIcon } from './icons/icon-pdf.svg'
import HeroBackground from './hero-background.svg'

const Hero = () => {
    return (
        <StyledHero>
            <h1>Hello there!</h1>
            <p>
                My name is Annie, and you've stumbled upon the very first
                website that I designed and coded entirely by myself. While I've
                been dabbling in various things for quite some time now, I
                recently took the plunge and decided to become a front-end
                developer. And here I am!
            </p>
            <CVButton>
                <CvIcon />
                Download CV
            </CVButton>
            <div className="icons">
                <InstaIcon />
                <LinkedInIcon />
                <TwitterIcon />
                <GithubIcon />
                <TelegramIcon />
            </div>
        </StyledHero>
    )
}

const CVButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 36px;
    margin-bottom: 20px;

    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 20px;

    border: 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 130%;

    color: #000000;

    svg {
        padding-right: 10px;
    }
`

const StyledHero = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    height: 310px;
    background-image: url(${HeroBackground});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 0px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 64px;

    & > h1,
    p,
    .icons {
        padding-bottom: 20px;
    }

    h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 37px;
        color: #ffffff;
        margin: 0;
    }

    p {
        max-width: 305px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 130%;
        text-align: center;

        color: #ffffff;
        margin: 0;
    }

    .icons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .icons > * {
        padding-right: 18px;
        padding-left: 18px;
    }
`

export default Hero
