import { ReactComponent as ProjectsIcon } from './icons/projects.svg'
import { ReactComponent as AboutIcon } from './icons/about.svg'
import { ReactComponent as BooksIcon } from './icons/books.svg'
import { ReactComponent as ContactsIcon } from './icons/contacts.svg'
import { ReactComponent as BurgerIcon } from './icons/storkBurger.svg'
import styled from 'styled-components/macro'
import { useState } from 'react'

const Menu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(true)

    return (
        <StyledMenu>
            <BurgerButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <BurgerIcon />
            </BurgerButton>
            <BurgerMenu hidden={isMenuOpen}>
                <MenuRow>
                    <ProjectsIcon />
                    Projects
                </MenuRow>
                <MenuRow>
                    <AboutIcon />
                    About me
                </MenuRow>
                <MenuRow>
                    <BooksIcon />
                    Books
                </MenuRow>
                <MenuRow>
                    <ContactsIcon />
                    Contacts
                </MenuRow>
            </BurgerMenu>
        </StyledMenu>
    )
}

const StyledMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 16px 20px 0px;

    position: fixed;

    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 1000;
`

const MenuRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 10px;
`

const BurgerButton = styled.div`
    width: 36px;
    height: 32px;
    padding: 8px 6px;

    border-radius: 100px;

    background: rgba(38, 0, 34, 0.7);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
`

const BurgerMenu = styled.div`
    pointer-events: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;

    text-align: center;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;

    background: rgba(232, 163, 221, 0.4);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);

    border-radius: 20px;
    color: black;
    text-align: center;

    min-width: 121px;
`

export default Menu
