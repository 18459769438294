import styled from "styled-components/macro";
import BooksList from './BooksList'

const BooksSection = () => {
    return (
        <>
            <div>
                <StyledSectionBooks>
                    <h1>Books</h1>
                    <h2>
                        I'm a totally a bookworm and really like books. I am
                        tired of answering the question "Which book do you
                        recommend?", so here are some of my favourites.
                    </h2>
                    <p>
                        One day, I will create beautiful bookshelves in this
                        section and everyone could find something to their
                        liking in my library.
                    </p>
                </StyledSectionBooks>
                <BooksList/>
            </div>
        </>
    )
}

const StyledSectionBooks = styled.div`
    font-family: Roboto;
    font-style: normal;
    display: flex; /*Если оставить тут flex, но убрать align-items, то пропадает нормальный градиент */
    flex-direction: column;
    align-items: flex-start;

    margin: 0;
    margin-top: -50px;
    padding: 0px;
    padding-right: 23px;
    padding-left: 23px;
    padding-top: 22px;

    & > * {
        padding-bottom: 16px;
    }

    &:last-child {
        padding-bottom: 0;
    }

    h1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        margin: 0;

        background: linear-gradient(114.12deg, #885bed -5.01%, #ff167c 110.37%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;

        color: #000000;
        margin: 0;
    }

    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 130%;

        color: #000000;
        opacity: 0.6;
        margin: 0;
    }

    & > :last-child {
        padding-bottom: 0;
    }
`

export default BooksSection
