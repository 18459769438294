import styled from 'styled-components/macro'
//иконки соц сетей
import { ReactComponent as InstaIcon } from './icons/instagram-icon.svg'
import { ReactComponent as LinkedInIcon } from './icons/linkedin-icon.svg'
import { ReactComponent as TwitterIcon } from './icons/twitter-icon.svg'
import { ReactComponent as GithubIcon } from './icons/github-icon.svg'
import { ReactComponent as TelegramIcon } from './icons/telegram-icon.svg'
// иконки меню внизу
import { ReactComponent as ProjectsIcon } from './menu-icons/projects.svg'
import { ReactComponent as AboutIcon } from './menu-icons/about.svg'
import { ReactComponent as BooksIcon } from './menu-icons/books.svg'
import { ReactComponent as ContactsIcon } from './menu-icons/contacts.svg'

const Footer = () => {
    return (
        <StyledFooter>
            <h1>Stay tuned</h1>
            <p>
                If you want to contact me, please send an email on
                annie@spacify.app
            </p>
            <p>
                Feel free to ping me using one of your favorite social networks.
                For me it's Twitter by the way.
            </p>
            <StyledIcons>
                <InstaIcon />
                <LinkedInIcon />
                <TwitterIcon />
                <GithubIcon />
                <TelegramIcon />
            </StyledIcons>

            <BottomSectionOfFooter>
                <Navigation>
                    <MenuItem>
                        <ProjectsIcon />
                        Projects
                    </MenuItem>
                    <div className="divider"></div>
                    <MenuItem>
                        <AboutIcon />
                        About me
                    </MenuItem>
                    <div className="divider"></div>
                    <MenuItem>
                        <BooksIcon />
                        Books
                    </MenuItem>
                    <div className="divider"></div>
                    <MenuItem>
                        <ContactsIcon />
                        Contacts
                    </MenuItem>
                </Navigation>
                <div class="b-cv">
                    <div class="folder"></div>
                    <p>Download CV</p>
                </div>
            </BottomSectionOfFooter>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    font-family: Roboto;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(68, 18, 85, 0.85);
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    color: white;
    text-align: center;

    margin: 0;
    padding-bottom: 16px;
    padding-right: 22px;
    padding-left: 21px;
    padding-top: 25px;

    & > * {
        padding-bottom: 16px;
    }

    h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;

        margin: 0;
    }

    p {
        font-weight: normal;
        font-size: 13px;
        line-height: 130%;
        margin: 0;
    }
`

const StyledIcons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 36px;
    padding-top: 8px;
`

const BottomSectionOfFooter = styled.div`
    font-family: Roboto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    & > * {
        padding-bottom: 24px;
    }

    .b-cv .folder {
    width: 138px;
    height: 94px;
    background-image: url('images/folder.png');
    background-position: center;
    background-repeat: no-repeat;
}

.b-cv .p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 130%;
    text-align: center;

    color: #ffffff;
}

`

const Navigation = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;

    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    padding-right: 48px;

    & > * {
        margin-right: 5px;
    }

    .divider {
        height: 1px;
        width: 100%;
        background: #000000;
        opacity: 0.2;
    }
`
const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;

    & * {
        margin-right: 5px;
    }
`

export default Footer
